import { RoleType } from 'common-toolbox/src/types/model/account';

export interface NavbarLinkType {
  title: string;
  url: string;
  roles: RoleType[];
  priority: number; // higher number means more priority
  highlighted?: boolean;
  isInternalToApplication?: boolean;
}

const createNavbarLinks = (
  callbackUrl: string | null | undefined,
): NavbarLinkType[] => [
  {
    title: 'Log in',
    url: `${process.env.NEXT_PUBLIC_STOREFRONT_HOST}/login/?callbackUrl=${
      callbackUrl || process.env.NEXT_PUBLIC_STOREFRONT_HOST
    }`,
    roles: ['unlogged'],
    priority: 0,
  },
  {
    title: 'Sign up',
    url: `${process.env.NEXT_PUBLIC_STOREFRONT_HOST}/signup/?callbackUrl=${
      callbackUrl || process.env.NEXT_PUBLIC_STOREFRONT_HOST
    }`,
    roles: ['unlogged'],
    priority: 0,
    highlighted: true,
  },
  {
    title: 'Dashboard',
    url: `${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/host/home/`,
    roles: ['host'],
    priority: -1,
    highlighted: true,
  },
  {
    title: 'Onboarding',
    url: `${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/host/onboarding/`,
    roles: ['candidate'],
    priority: -1,
    highlighted: true,
  },
  {
    title: 'Bookings',
    url: `${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/schedule/`,
    roles: ['loggedin'],
    priority: 0,
  },
  {
    title: 'Chat',
    url: `${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/messages/`,
    roles: ['loggedin'],
    priority: 0,
  },
  {
    title: 'My agenda',
    url: `${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/host-agenda/`,
    roles: ['host'],
    priority: 0,
  },
  {
    title: 'Favorites',
    url: `${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/favorites/`,
    roles: ['loggedin'],
    priority: 0,
  },
];

export default createNavbarLinks;
