'use client';

import { Suspense } from 'react';

import { getAccountRoles } from '../../helpers/account';
import { useAccount } from '../../hooks/useAccount';
import ExposedLinks from '../Navbar/ExposedLinks';
import NavigationMenu from '../NavigationMenu';

function NavbarClientComponent({ showSearch }: { showSearch?: boolean }) {
  const { account, status } = useAccount();

  const roles = getAccountRoles(account);

  return (
    status === 'ready' && (
      <Suspense>
        <NavigationMenu roles={roles} account={account} />
        <ExposedLinks roles={roles} showSearch={showSearch} />
      </Suspense>
    )
  );
}

export default NavbarClientComponent;
