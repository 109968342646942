import { useQuery } from '@tanstack/react-query';

import { useSession } from '../auth/useSession';
import { Account } from '../types/model/account';

type Status = 'initial' | 'loading' | 'ready';
type UseAccountState = { status: Status; account: Account | null };

const AUTHENTICATION_HOST = process.env.NEXT_PUBLIC_AUTHENTICATION_HOST;

export function useAccount(): UseAccountState {
  const { status, data: session } = useSession();

  const account = useQuery({
    queryKey: ['account', session?.user?.id],
    queryFn: async () => {
      const response = await fetch(`${AUTHENTICATION_HOST}/api/account`, {
        credentials: 'include',
      });

      if (response.status !== 200) {
        return null;
      }

      return response.json();
    },
    refetchOnWindowFocus: false,
    enabled: status === 'authenticated',
    initialData: null,
  });

  return {
    status: account.isLoading || account.isFetching ? 'loading' : 'ready',
    account: account.data,
  };
}
