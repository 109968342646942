'use client';

import Link from 'next/link';

import NavbarSearchTrigger from 'common-toolbox/src/components/NavigationMenu/NavbarSearchTrigger';
import { RoleType } from 'common-toolbox/src/types/model/account';

import CSS from './ExposedLinks.module.scss';
import LinkDecorator from './LinkDecorator';
import useExposedNavbarLinks from './hooks/useExposedNavbarLinks';

function ExposedLinks({
  roles,
  showSearch,
}: {
  roles: RoleType[];
  showSearch?: boolean;
}) {
  const links = useExposedNavbarLinks({ roles });
  return (
    <div className={CSS.ExposedLinksContainer}>
      {showSearch && <NavbarSearchTrigger />}
      <ul className={CSS.ExposedLinks}>
        {links.map((link) => {
          const LinkComponent = link.isInternalToApplication ? Link : 'a';
          return (
            <li
              key={link.url}
              className={link?.highlighted ? undefined : CSS.LinkListItem}
            >
              <LinkDecorator link={link}>
                <LinkComponent
                  href={link.url}
                  className={link?.highlighted ? CSS.HighlightedLink : CSS.Link}
                  rel="noopener noreferrer"
                >
                  {link.title}
                </LinkComponent>
              </LinkDecorator>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ExposedLinks;
